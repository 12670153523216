import {includes, isEqual} from "lodash";

import {DealTypeSlug, OfferDealType, UOfferTypes} from "../../offer/utils/constants_offer";
import {TypeSearchFilterOptions} from "./TypeSearchFilterOptions";

export const parseFormOfferTypeToSlug = (offerType: TypeSearchFilterOptions[]): UOfferTypes => {
    if (isEqual(offerType, ["lot"])) {
        return "dzialki";
    }
    if (
        (offerType.length === 2 &&
            includes(offerType, TypeSearchFilterOptions.PRIMARY_APARTMENT) &&
            includes(offerType, TypeSearchFilterOptions.AFTERMARKET_APARTMENT)) ||
        (offerType.length === 1 && includes(offerType, TypeSearchFilterOptions.PRIMARY_APARTMENT)) ||
        (offerType.length === 1 && includes(offerType, TypeSearchFilterOptions.AFTERMARKET_APARTMENT))
    ) {
        return "mieszkania";
    }
    if (
        (offerType.length === 2 &&
            includes(offerType, TypeSearchFilterOptions.PRIMARY_HOUSE) &&
            includes(offerType, TypeSearchFilterOptions.AFTERMARKET_HOUSE)) ||
        (offerType.length === 1 && includes(offerType, TypeSearchFilterOptions.AFTERMARKET_HOUSE)) ||
        (offerType.length === 1 && includes(offerType, TypeSearchFilterOptions.PRIMARY_HOUSE))
    ) {
        return "domy";
    }
    return "nieruchomosci";
};

// export const parseFormOfferTypeToQuery = (offerType: TypeSearchFilterOptions[]) => {
//     const slugOfferType = parseFormOfferTypeToSlug(offerType);
//     switch (slugOfferType) {
//         case "dzialki":
//             return "lot";
//         case "mieszkania":
//             return "apartment";
//         case "domy":
//             return "house";
//         default:
//             return "property";
//     }
// };
//
// export const parseQueryOfferTypeToForm = (offerType: "apartment" | "house" | "property" | "lot"): TypeSearchFilterOptions[] => {
//     switch (offerType) {
//         case "apartment":
//             return [TypeSearchFilterOptions.AFTERMARKET_APARTMENT, TypeSearchFilterOptions.PRIMARY_APARTMENT];
//         case "house":
//             return [TypeSearchFilterOptions.PRIMARY_HOUSE, TypeSearchFilterOptions.AFTERMARKET_HOUSE];
//         case "lot":
//             return [TypeSearchFilterOptions.LOT];
//         case "property":
//             return [
//                 TypeSearchFilterOptions.AFTERMARKET_HOUSE,
//                 TypeSearchFilterOptions.PRIMARY_HOUSE,
//                 TypeSearchFilterOptions.PRIMARY_APARTMENT,
//                 TypeSearchFilterOptions.AFTERMARKET_APARTMENT
//             ];
//         default:
//             return [];
//     }
// };

export const parseFormDealTypeToSlug = (dealType: OfferDealType | DealTypeSlug | string | undefined): DealTypeSlug | undefined => {
    switch (dealType) {
        case OfferDealType.SELL:
        case DealTypeSlug.SELL:
            return DealTypeSlug.SELL;
        case OfferDealType.RENT:
        case DealTypeSlug.RENT:
            return DealTypeSlug.RENT;
        default:
            return undefined;
    }
};
