import {css} from "@emotion/react";
import {SAS_PLACEMENTS} from "@web2/gh_page_object_models";

import {AdPlacement} from "../../advertisement/components/AdPlacement";
import {PlacementDest} from "../../advertisement/PlacementDest";
import {PlacementPage} from "../../advertisement/PlacementPage";
import {getHousingPlatformUrl} from "../../offer/utils/constants_offer";
import {ARTICLE_LIST_WIDE_BREAKPOINT} from "./ArticleBox";

import placeholder1239x242 from "../../../assets/ad_placeholders/platforma_baner_1239x242.png";

const placeholder = {
    image: placeholder1239x242,
    url: getHousingPlatformUrl({source: "artykul"})
};

interface Props {
    adTargeting: string;
}

export const ArticleListDesktopPlacement = (props: Props) => {
    return (
        <div css={adPlacementHolder} data-testid={SAS_PLACEMENTS.ARTICLE_LIST}>
            <AdPlacement
                dest={PlacementDest.DESKTOP}
                page={PlacementPage.article_list__desktop}
                placeholder={placeholder}
                breakpoint={ARTICLE_LIST_WIDE_BREAKPOINT}
                target={props.adTargeting}
            />
        </div>
    );
};

const adPlacementHolder = css`
    display: none;

    @media (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        display: block;
    }
`;
