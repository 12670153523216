import * as React from "react";
import {css, Theme} from "@emotion/react";
import {appLink} from "@web2/gh_routes";
import {dateTimeFormat, formatDate} from "@web2/string_utils";

import {textEllipsis} from "../../styles/helpers";
import {SchemaNewsArticle} from "../schemas/SchemaNewsArticle";
import {ARTICLE_LIST_WIDE_BREAKPOINT} from "./ArticleBox";

import imgPlaceholder from "../../styles/assets/images/article_box_img_placeholder.png";

interface IProps {
    mainArticle: {
        slug: string;
        publication_date: string;
        content_updated_date: string;
        author_name: string;
        title: string;
        lead: string;
        image_thumbs: {
            a_img_555x321: string;
        } | null;
    };
    isH2?: boolean;
}

export const ARTICLE_BOX_WIDE_HEIGHT_WIDE = "321px";

export const ArticleBoxWide = (props: IProps) => {
    const date = formatDate(props.mainArticle.content_updated_date, dateTimeFormat.shortDate);

    return (
        <article>
            <a href={appLink.article.detailOrCategoryList({slug: props.mainArticle.slug})} css={articleHolder}>
                <div css={textHolder}>
                    <p css={dateAuthor}>
                        {date}, {props.mainArticle.author_name}
                    </p>

                    {props.isH2 ? <h2 css={title}>{props.mainArticle.title}</h2> : <h1 css={title}>{props.mainArticle.title}</h1>}

                    <p css={subtitle}>{props.mainArticle.lead}</p>
                </div>

                <div css={imgHolder}>
                    <img width={555} height={321} src={props.mainArticle.image_thumbs?.a_img_555x321 || imgPlaceholder} alt={props.mainArticle.title} />
                </div>
            </a>

            <SchemaNewsArticle article={props.mainArticle} articleImage={props.mainArticle.image_thumbs && props.mainArticle.image_thumbs.a_img_555x321} />
        </article>
    );
};

const articleHolder = css`
    display: flex;
    flex-direction: column-reverse;
    margin: 3rem 0;
    transition: box-shadow 200ms ease-out;

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        flex-direction: row;
        height: ${ARTICLE_BOX_WIDE_HEIGHT_WIDE};
    }

    &:hover {
        text-decoration: none;
        box-shadow: 0 0.2rem 1rem 0.4rem rgba(0, 0, 0, 0.1);

        img {
            transform: scale(1.1);
        }
    }
`;

const textHolder = css`
    flex-basis: 45%;
    padding: 1rem 1.5rem 1.5rem;
`;

const dateAuthor = (theme: Theme) => css`
    margin: 0.5rem 0 1rem;
    color: ${theme.colors.gray_light};
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
`;

const title = (theme: Theme) => css`
    ${textEllipsis(3)}
    font-size: 2.4rem;
    line-height: 1.3;
    margin: 0;
    color: ${theme.colors.gray_dark};
    font-weight: 300;

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        font-size: 3.4rem;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

const subtitle = (theme: Theme) => css`
    ${textEllipsis(5)}
    color: ${theme.colors.gray_darker};
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.5;
`;

const imgHolder = css`
    overflow: hidden;
    flex: 1 1 auto;
    height: 175px;

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        height: ${ARTICLE_BOX_WIDE_HEIGHT_WIDE};
        flex-basis: 50%;
    }

    img {
        transition: transform 700ms cubic-bezier(0.25, 0.8, 0.25, 1);
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
