import * as React from "react";
import {css, Theme} from "@emotion/react";

import {ARTICLE_BOX_HEIGHT, ARTICLE_LIST_MID_BREAKPOINT, ARTICLE_LIST_WIDE_BREAKPOINT} from "./ArticleBox";
import {blogLoadingAnimation} from "./atoms";

export const ArticleBoxLoader = () => {
    return (
        <li css={articleHiding}>
            <div css={articleHolder}>
                <div css={imgPlaceholder} />

                <div css={textHolder}>
                    <div css={dateAuthor}>
                        <div /> <div />
                    </div>

                    <div css={titleLinesHolder}>
                        <div /> <div /> <div />
                    </div>

                    <div css={leadLinesHolder}>
                        <div /> <div /> <div /> <div /> <div />
                    </div>
                </div>
            </div>
        </li>
    );
};

const articleHiding = css`
    width: 100%;

    @media screen and (min-width: ${ARTICLE_LIST_MID_BREAKPOINT}px) {
        max-width: 375px;
    }

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        max-width: 310px;
    }

    &.hide {
        display: none;
    }
`;

const articleHolder = css`
    display: flex;
    flex-direction: column;
    margin: 0 auto 2rem;
    max-width: 375px;
    width: 100%;
    background-color: white;
    overflow: hidden;
    color: inherit;

    &.hide {
        display: none;
    }

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        height: ${ARTICLE_BOX_HEIGHT}px;
    }
`;

const imgPlaceholder = (theme: Theme) => css`
    ${blogLoadingAnimation};
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
    width: 375px;
    background-color: ${theme.colors.gray_very_bright};
`;

const textHolder = (theme: Theme) => css`
    flex-grow: 1;
    padding: 1rem 1.5rem 1.5rem;
    border-left: 1px solid ${theme.colors.gray_very_bright};
    border-right: 1px solid ${theme.colors.gray_very_bright};
    border-bottom: 1px solid ${theme.colors.gray_very_bright};
`;

const dateAuthor = (theme: Theme) => css`
    display: flex;
    margin: 0.5rem 0 1rem;

    > div {
        ${blogLoadingAnimation};
        height: 12px;
        background-color: ${theme.colors.gray_very_bright};
    }

    > div:first-of-type {
        width: 50px;
        margin-right: 0.5rem;
    }

    > div:last-of-type {
        width: 100px;
    }
`;

const titleLinesHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;

    > div {
        ${blogLoadingAnimation};
        background-color: ${theme.colors.gray_very_bright};
        height: 18px;
        width: 100%;
        margin-bottom: 1rem;
    }

    > div:last-of-type {
        width: 40%;
        margin-bottom: 0;
    }
`;

const leadLinesHolder = (theme: Theme) => css`
    > div {
        ${blogLoadingAnimation};
        background-color: ${theme.colors.gray_very_bright};
        height: 10px;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    > div:last-of-type {
        width: 45%;
    }
`;
