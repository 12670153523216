import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const TwitterBirdIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M9.3198 2.65732C9.00036 2.79931 8.65658 2.89509 8.29587 2.93795C8.66417 2.71747 8.94656 2.36822 9.07974 1.95159C8.73525 2.15602 8.3539 2.30436 7.94768 2.38444C7.62259 2.03802 7.15922 1.82141 6.64646 1.82141C5.66203 1.82141 4.86387 2.61957 4.86387 3.60417C4.86387 3.74369 4.87957 3.87969 4.91009 4.01039C3.42842 3.93596 2.11468 3.22635 1.23538 2.14773C1.08193 2.4109 0.994084 2.71711 0.994084 3.04414C0.994084 3.66256 1.30876 4.2083 1.78713 4.52792C1.49503 4.51857 1.22004 4.43831 0.979621 4.30479C0.979444 4.31219 0.979444 4.31978 0.979444 4.32736C0.979444 5.19096 1.59398 5.91133 2.4096 6.07538C2.26003 6.11594 2.10251 6.13782 1.93988 6.13782C1.82487 6.13782 1.71322 6.1267 1.60439 6.10571C1.8314 6.81391 2.48968 7.3295 3.26967 7.34379C2.65955 7.82198 1.89102 8.10702 1.05564 8.10702C0.911887 8.10702 0.769894 8.09855 0.630371 8.08197C1.41953 8.58803 2.35651 8.88295 3.36316 8.88295C6.6424 8.88295 8.43539 6.16657 8.43539 3.81072C8.43539 3.73346 8.4338 3.65638 8.43045 3.57983C8.77864 3.329 9.08097 3.01486 9.3198 2.65732Z" />
        </SvgIcon>
    );
};
