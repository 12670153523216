import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const MailIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M5.03584 5.602L9.26073 1.94043H0.810956L5.03584 5.602ZM3.8947 5.19667L5.03584 6.13334L6.15911 5.19667L9.26073 7.85527H0.810956L3.8947 5.19667ZM0.529297 7.57361V2.22209L3.62755 4.89785L0.529297 7.57361ZM9.54239 7.57361V2.22209L6.44414 4.89785L9.54239 7.57361Z" />
        </SvgIcon>
    );
};
