import * as React from "react";
import {useState} from "react";
import {css, Theme} from "@emotion/react";
import {map} from "lodash";
import {ARTICLE_CATEGORY_LINK} from "@web2/gh_page_object_models/blog/test_ids";
import {appLink} from "@web2/gh_routes";
import {Modal} from "@web2/modal";

import {IArticleCategory} from "../../app/interfaces/response/article_category_list";
import {ArrowDownIcon} from "../../offer/list/components/icons/ArrowDownIcon";
import {ArticleCategorySlug} from "../utils/category_name_utils";

interface IProps {
    categories: IArticleCategory[];
    activeCategoryId?: number;
}

export const ArticleCategories = (props: IProps) => {
    const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);
    const articleCategoryFirstItemSlug = ArticleCategorySlug.FOR_PROFESSIONALS;
    const articleCategoriesList = [...props.categories];
    articleCategoriesList.sort((x, y) => {
        return x.slug === articleCategoryFirstItemSlug ? -1 : y.slug === articleCategoryFirstItemSlug ? 1 : 0;
    });

    return (
        <>
            <ul css={list}>
                {map(articleCategoriesList, (category) => {
                    return (
                        <li key={category.id}>
                            <a
                                css={listItemBody}
                                href={appLink.article.detailOrCategoryList({slug: category.slug})}
                                className={props.activeCategoryId === category.id ? "active" : ""}
                                data-testid={ARTICLE_CATEGORY_LINK}
                            >
                                {category.name}
                            </a>
                        </li>
                    );
                })}
            </ul>

            <div css={dropdownWrapper} onClick={() => setCategoriesModalOpen(!isCategoriesModalOpen)}>
                Tematy <ArrowDownIcon size="1.2" fillColor="#9069c0" />
                <Modal
                    modalState={isCategoriesModalOpen}
                    onModalClose={() => setCategoriesModalOpen(!isCategoriesModalOpen)}
                    type="window"
                    contentStyles={modalContentStyles}
                >
                    <div css={modalBody}>
                        <div css={modalListHeading}>Wybierz temat</div>

                        <ul css={modalCategoriesList}>
                            {map(articleCategoriesList, (category) => {
                                return (
                                    <li key={category.id}>
                                        <a
                                            css={listItemBody}
                                            href={appLink.article.detailOrCategoryList({slug: category.slug})}
                                            className={props.activeCategoryId === category.id ? "active" : ""}
                                        >
                                            {category.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>

                        <div css={closeModal} onClick={() => setCategoriesModalOpen(false)}>
                            Zamknij
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

const modalContentStyles = {
    position: "absolute",
    backgroundColor: "#fff",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    borderRadius: 0,
    width: 320
};

const list = (theme: Theme) => css`
    display: none;
    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        list-style-type: none;
        padding: 0;
        border-bottom: 1px solid #dddddd;
        flex-wrap: wrap;
        width: 100%;
    }
`;

export const modalCategoriesList = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    width: 100%;
    margin: 0;

    li:last-of-type > a {
        border: none;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;

export const listItemBody = (theme: Theme) => css`
    position: relative;
    display: inline-block;
    padding: 0.7rem 1.5rem;
    border-bottom: 2px solid transparent;
    color: ${theme.colors.gray_dark};
    line-height: 1.5;
    text-decoration: none;
    transition: all 200ms ease-out;
    will-change: color, border-bottom-color;
    cursor: pointer;
    white-space: nowrap;

    &:hover,
    &:focus,
    &.active {
        color: ${theme.colors.brand_primary};
        text-decoration: none;
        border-bottom-color: ${theme.colors.brand_primary};
    }

    &.active {
        font-weight: 500;
    }

    @media (max-width: calc(${theme.breakpoints.screen_md} - 1px)) {
        padding: 1.2rem 1.5rem;
        width: 100%;
        border-bottom: 1px solid ${theme.colors.gray_bright};
    }
`;

const dropdownWrapper = (theme: Theme) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${theme.colors.brand_primary};

    svg {
        margin-left: 1rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;

const modalBody = css`
    padding: 1.5rem;
`;

const modalListHeading = (theme: Theme) => css`
    display: block;
    color: ${theme.colors.gray_dark};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    margin-top: 0;
`;

const closeModal = (theme: Theme) => css`
    color: ${theme.colors.gray_dark};
    text-align: end;
`;
