import * as React from "react";
import classNames from "classnames";

import {SvgIcon} from "./SvgIcon";

interface IProps {
    className?: string;
    fillColor?: string;
    size?: string;
}

export const GlobeIcon = (props: IProps) => {
    const {fillColor, size} = props;
    const className = classNames(props.className);

    return (
        <SvgIcon size={size || "1"} className={className} fillColor={fillColor}>
            <path d="M5 1C2.8 1 1 2.8 1 5C1 7.2 2.8 9 5 9C7.2 9 9 7.2 9 5C9 2.8 7.2 1 5 1ZM7.76 3.4H6.6C6.48 2.88 6.28 2.44 6.04 1.96C6.76 2.24 7.4 2.72 7.76 3.4ZM5 1.8C5.32 2.28 5.6 2.8 5.76 3.4H4.24C4.4 2.84 4.68 2.28 5 1.8ZM1.92 5.8C1.84 5.56 1.8 5.28 1.8 5C1.8 4.72 1.84 4.44 1.92 4.2H3.28C3.24 4.48 3.24 4.72 3.24 5C3.24 5.28 3.28 5.52 3.28 5.8H1.92ZM2.24 6.6H3.4C3.52 7.12 3.72 7.56 3.96 8.04C3.24 7.76 2.6 7.28 2.24 6.6ZM3.4 3.4H2.24C2.64 2.72 3.24 2.24 3.96 1.96C3.72 2.44 3.52 2.88 3.4 3.4ZM5 8.2C4.68 7.72 4.4 7.2 4.24 6.6H5.76C5.6 7.16 5.32 7.72 5 8.2ZM5.92 5.8H4.08C4.04 5.52 4 5.28 4 5C4 4.72 4.04 4.48 4.08 4.2H5.96C6 4.48 6.04 4.72 6.04 5C6.04 5.28 5.96 5.52 5.92 5.8ZM6.04 8.04C6.28 7.6 6.48 7.12 6.6 6.6H7.76C7.4 7.28 6.76 7.76 6.04 8.04ZM6.76 5.8C6.8 5.52 6.8 5.28 6.8 5C6.8 4.72 6.76 4.48 6.76 4.2H8.12C8.2 4.44 8.24 4.72 8.24 5C8.24 5.28 8.2 5.56 8.12 5.8H6.76Z" />
        </SvgIcon>
    );
};
