import React from "react";
import {appLink} from "@web2/gh_routes";
import {dateTimeFormat, formatDate} from "@web2/string_utils";

import {currentStaticsDir} from "../../app/constants/current_statics_dir";
import {ghCommonUrl} from "../../app/utils/read_environment_variables";

interface IProps {
    article: {
        lead: string;
        publication_date: string;
        content_updated_date: string;
        slug: string;
        title: string;
    };
    articleImage: string | null;
}

export const SchemaNewsArticle = (props: IProps) => {
    const {article} = props;
    const url = `${ghCommonUrl}${appLink.article.detailOrCategoryList({slug: article.slug})}`;
    const publicationDate = formatDate(article.publication_date, dateTimeFormat.shortDate);
    const contentUpdatedDate = formatDate(article.content_updated_date, dateTimeFormat.shortDate);
    const json = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        url: url,
        publisher: {
            "@type": "Organization",
            name: "GetHome.pl",
            logo: `${ghCommonUrl}/${currentStaticsDir}/gethome_logo_600x138.png`
        },
        headline: article.title,
        mainEntityOfPage: url,
        articleBody: article.lead,
        image: [`${props.articleImage}`],
        datePublished: publicationDate,
        dateModified: contentUpdatedDate
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
