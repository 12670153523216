import * as React from "react";
import {css, Theme} from "@emotion/react";
import {map} from "lodash";
import {darken} from "polished";

import {IArticleCategory} from "../../app/interfaces/response/article_category_list";

interface IProps {
    cityCategories: IArticleCategory[];
    setCityCategory: (cat: string) => void;
    activeCategory: string;
    activeCategoryName: string;
}

export const ArticleDistricts = (props: IProps) => {
    return (
        <div css={districtsHolder}>
            <ul css={districtsList}>
                {map(props.cityCategories, (city) => {
                    return (
                        <li
                            key={city.uuid}
                            css={districtPill}
                            className={props.activeCategory === city.uuid ? "active" : ""}
                            onClick={() => props.setCityCategory(city.uuid)}
                        >
                            {city.name}
                        </li>
                    );
                })}
            </ul>

            <span css={bgRightOverlay} />
        </div>
    );
};

const districtsList = css`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 0;
    overflow-x: auto;
    margin-top: 1rem;
    margin-bottom: 4rem;
`;

const districtPill = (theme: Theme) => css`
    height: 30px;
    min-width: 105px;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    background-color: ${theme.colors.brand_primary};
    color: #fff;
    margin-right: 1.2rem;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: center;
    cursor: pointer;

    &.active {
        background-color: #604680;

        &:hover {
            background-color: ${darken(0.05, "#604680")};
        }
    }

    &:hover {
        background-color: ${darken(0.05, theme.colors.brand_primary.toString())};
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        min-width: 95px;
    }
`;

const districtsHolder = css`
    position: relative;
`;

const bgRightOverlay = css`
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    width: 40px;
    bottom: 0;
    display: block;
    right: 0;
    background-image: linear-gradient(to left, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
`;
