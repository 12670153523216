import React from "react";
import {css, Theme} from "@emotion/react";
import {FacebookFIcon, GlobeIcon, InstagramIcon, LinkedinIcon, MailIcon, TwitterBirdIcon} from "@web2/icons";

interface IProps {
    authorEmail: string | null;
    authorFacebook: string | null;
    authorInstagram: string | null;
    authorLinkedin: string | null;
    authorTwitter: string | null;
    authorWebPage: string | null;
    className?: string;
}

const socialItem = (url: string, icon: JSX.Element, isEmail?: boolean) => (
    <li>
        <a css={socialIconsListItem} href={isEmail ? `mailto:${url}` : url} target="_blank">
            {icon}
        </a>
    </li>
);

export const AuthorSocial = (props: IProps) => {
    if (!props.authorFacebook && !props.authorInstagram && !props.authorTwitter && !props.authorLinkedin && !props.authorEmail && !props.authorWebPage) {
        return null;
    }

    return (
        <ul css={socialIconsList} className={props.className}>
            {props.authorFacebook && socialItem(props.authorFacebook, <FacebookFIcon size="1.7" fillColor="#fff" />)}
            {props.authorInstagram && socialItem(props.authorInstagram, <InstagramIcon size="1.7" fillColor="#fff" />)}
            {props.authorTwitter && socialItem(props.authorTwitter, <TwitterBirdIcon size="1.7" fillColor="#fff" />)}
            {props.authorLinkedin && socialItem(props.authorLinkedin, <LinkedinIcon size="1.7" fillColor="#fff" />)}
            {props.authorEmail && socialItem(props.authorEmail, <MailIcon size="1.7" fillColor="#fff" />, true)}
            {props.authorWebPage && socialItem(props.authorWebPage, <GlobeIcon size="1.7" fillColor="#fff" />)}
        </ul>
    );
};

const socialIconsListItem = (theme: Theme) => css`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${theme.colors.brand_primary};
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
`;

const socialIconsList = (theme: Theme) => css`
    margin: 2rem 0 1rem 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;

    @media (min-width: ${theme.breakpoints_correct.screen_md}) {
        justify-content: left;
        margin: 4rem 0 1rem 0;
    }
`;
