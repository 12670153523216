import Bugsnag from "@bugsnag/js";

import {isTesting} from "../read_environment_variables";

// err.name field is the first text shown in bold in bugsnag inbox
interface IError {
    message: string;
    name?: string;
    filename?: string;
    error?: Error;
    response?: Response;
}

export const notifyBugsnag = (err: IError, errorInfo: string, errorData: Object = {}) => {
    if (isTesting) {
        // ignore Bugsnag calls in jest testing env
        return;
    }

    // notify regarding err
    if (err.error) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData: {errorInfo, errorData}});
        });
    } else {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", {errorInfo, errorData});
            }
        );
    }
};
