import * as React from "react";
import {css, Theme} from "@emotion/react";

import {ARTICLE_LIST_WIDE_BREAKPOINT} from "./ArticleBox";
import {ARTICLE_BOX_WIDE_HEIGHT_WIDE} from "./ArticleBoxWide";
import {blogLoadingAnimation} from "./atoms";

export const ArticleBoxWideLoader = () => {
    return (
        <div css={holder}>
            <div css={textHolder}>
                <div css={dateAuthor}>
                    <div /> <div />
                </div>

                <div css={titleFirstLine} />
                <div css={titleSecondLine} />

                <div css={textFullLine} />
                <div css={textFullLine} />
                <div css={textFullLine} />
                <div css={textFullLine} />
                <div css={textPartLine} />
            </div>

            <div css={imgHolder}>
                <div />
            </div>
        </div>
    );
};

const holder = css`
    display: flex;
    flex-direction: column-reverse;
    margin: 3rem 0;
    text-decoration: none;

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        flex-direction: row;
        height: ${ARTICLE_BOX_WIDE_HEIGHT_WIDE};
    }
`;

const textHolder = css`
    flex-direction: column;
    flex-basis: 45%;
    padding: 1rem 1.5rem 1.5rem;
`;

const dateAuthor = (theme: Theme) => css`
    display: flex;
    margin: 0.5rem 0 2rem;

    > div {
        ${blogLoadingAnimation};
        height: 12px;
        background-color: ${theme.colors.gray_very_bright};
    }

    > div:first-of-type {
        width: 50px;
        margin-right: 0.5rem;
    }

    > div:last-of-type {
        width: 100px;
    }
`;
const titleFirstLine = (theme: Theme) => css`
    ${blogLoadingAnimation};
    background-color: ${theme.colors.gray_very_bright};
    height: 30px;
    width: 100%;
    margin-bottom: 1rem;
`;

const titleSecondLine = (theme: Theme) => css`
    ${blogLoadingAnimation};
    background-color: ${theme.colors.gray_very_bright};
    height: 30px;
    width: 40%;
    margin-bottom: 2.5rem;
`;

const textFullLine = (theme: Theme) => css`
    ${blogLoadingAnimation};
    background-color: ${theme.colors.gray_very_bright};
    height: 12px;
    width: 100%;
    margin-bottom: 0.5rem;
`;

const textPartLine = (theme: Theme) => css`
    ${blogLoadingAnimation};
    background-color: ${theme.colors.gray_very_bright};
    height: 13px;
    width: 40%;
`;

const imgHolder = (theme: Theme) => css`
    ${blogLoadingAnimation};
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
    background-color: ${theme.colors.gray_very_bright};

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        height: ${ARTICLE_BOX_WIDE_HEIGHT_WIDE};
    }
`;
