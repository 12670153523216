export const BREADCRUMBS_TEST_IDS = {
    HOLDER: "breadcrumbs-holder",
    ITEM: `breadcrumbs-item`
};

export const PAGINATION_TEST_IDS = {
    GET_ITEM_TEST_ID: (page: number) => `page-${page}-link`
};

export const COMMON_PAGE_SECTIONS = {
    FOOTER_LOWER: "page-footer-lower"
};

export const SAS_PLACEMENTS = {
    ARTICLE_LIST: "placement-article-list"
};
