import React from "react";
import styled from "@emotion/styled";

import placeholder from "../../styles/assets/images/user_placeholder_big.svg";

interface IProps {
    className?: string;
    image?: string | null;
    name: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    size?: number;
}

export const AuthorAvatar = (props: IProps) => {
    return (
        <AuthorAvatarHolder size={props.size || 160} className={props.className} onClick={props.onClick}>
            <img src={props.image || placeholder} alt={props.name} height={props.size || 160} width={props.size || 160} loading="lazy" />
        </AuthorAvatarHolder>
    );
};

const AuthorAvatarHolder = styled.div<{size: number}>`
    width: ${(props) => props.size + `px` || "160px"};
    height: ${(props) => props.size + `px` || "160px"};
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
