import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";

import {IArticleListArticle} from "../../app/interfaces/response/article_list";
import {ArticleBox} from "./ArticleBox";

interface IProps {
    articles: IArticleListArticle[];
    activeCategory: string;
}

export const ArticleDistrictList = (props: IProps) => {
    return (
        <ul css={articleList}>
            {map(props.articles, (article) => {
                const isInactiveChildCategory = article.categories.some((cat) => cat.parent && cat.uuid !== props.activeCategory);
                return (
                    <ArticleListElement key={article.uuid} hide={isInactiveChildCategory}>
                        <ArticleBox article={article} hide={isInactiveChildCategory} />
                    </ArticleListElement>
                );
            })}
        </ul>
    );
};

const articleList = (theme: Theme) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    list-style: none;
    padding: 0;

    &:after {
        content: "";
        display: block;
        width: 310px;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        justify-content: space-between;
    }
`;

const ArticleListElement = styled.li<{hide: boolean}>`
    display: ${(props) => (props.hide ? "none" : "block")};
`;
