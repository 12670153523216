export const parseFirstUnicodeLetterToLatin = (s: string) => {
    const firstLetter = s.slice(0, 1).toLowerCase();
    const firstLatinLetter = (() => {
        switch (firstLetter) {
            case "ą":
                return "a";
            case "ę":
                return "e";
            case "ć":
                return "c";
            case "ś":
                return "s";
            case "ł":
                return "l";
            case "ź":
            case "ż":
                return "z";
            default:
                return firstLetter;
        }
    })();
    return firstLatinLetter.toUpperCase() + s.slice(1);
};
