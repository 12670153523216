import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {ARTICLE_BOX_IDS} from "@web2/gh_page_object_models";
import {appLink} from "@web2/gh_routes";
import {dateTimeFormat, formatDate} from "@web2/string_utils";

import {textEllipsis} from "../../styles/helpers";
import {SchemaNewsArticle} from "../schemas/SchemaNewsArticle";

import imgPlaceholder from "../../styles/assets/images/article_box_img_placeholder.png";

export interface IArticleBox {
    uuid?: string; // This will be `id` in API1
    id?: string;
    slug: string;
    // image: string | null;
    publication_date: string;
    content_updated_date: string;
    author_name: string;
    title: string;
    lead: string;
    text?: string;
    image_thumbs: {
        a_img_310x175: string;
        a_img_555x321: string;
    } | null;
}

interface IProps {
    article: IArticleBox;
    hide?: boolean;
}

export const ARTICLE_LIST_MID_BREAKPOINT = 361;
export const ARTICLE_LIST_WIDE_BREAKPOINT = 685;
export const ARTICLE_BOX_HEIGHT = 430;

export const ArticleBox = (props: IProps) => {
    const date = formatDate(props.article.content_updated_date, dateTimeFormat.shortDate);

    return (
        <article css={articleHiding} className={props.hide ? "hide" : ""} data-testid={ARTICLE_BOX_IDS.WRAPPER}>
            <a href={appLink.article.detailOrCategoryList({slug: props.article.slug})} css={articleHolder}>
                <picture css={imgHolder}>
                    <source
                        media={`(min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px)`}
                        srcSet={props.article.image_thumbs?.a_img_310x175 || imgPlaceholder}
                        width={310}
                        height={175}
                    />
                    <source
                        media={`(min-width: ${ARTICLE_LIST_MID_BREAKPOINT}px)`}
                        srcSet={props.article.image_thumbs?.a_img_555x321 || imgPlaceholder}
                        width={555}
                        height={321}
                    />
                    <img src={props.article.image_thumbs?.a_img_310x175 || imgPlaceholder} alt={props.article.title} width={310} height={175} loading="lazy" />
                </picture>

                <div css={textHolder}>
                    <p css={dateAuthor}>
                        {date}, {props.article.author_name}
                    </p>

                    <ArticleBoxHeading>{props.article.title}</ArticleBoxHeading>

                    <p dangerouslySetInnerHTML={{__html: props.article.text ? props.article.text : props.article.lead}} css={articleLead}></p>
                </div>
            </a>

            <SchemaNewsArticle article={props.article} articleImage={props.article.image_thumbs && props.article.image_thumbs.a_img_555x321} />
        </article>
    );
};

const articleHiding = css`
    height: 100%;
    padding-bottom: 2rem;

    &.hide {
        display: none;
    }
`;

const articleHolder = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 310px;
    background-color: white;
    transition: box-shadow 200ms ease-out;
    overflow: hidden;
    color: inherit;

    &:hover {
        box-shadow: 0 0.2rem 1rem 0.4rem rgba(0, 0, 0, 0.1);
        text-decoration: none;

        img {
            transform: scale(1.1);
        }
    }

    &.hide {
        display: none;
    }

    @media screen and (min-width: ${ARTICLE_LIST_MID_BREAKPOINT}px) {
        max-width: 375px;
    }

    @media screen and (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        max-width: 310px;
        height: ${ARTICLE_BOX_HEIGHT}px;
    }
`;

const textHolder = (theme: Theme) => css`
    flex-direction: column;
    padding: 1rem 1.5rem 1.5rem;
    color: ${theme.colors.gray_darker};
    font-weight: 300;
`;

const dateAuthor = (theme: Theme) => css`
    margin: 0.5rem 0 1rem;
    color: ${theme.colors.gray_light};
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
`;

const ArticleBoxHeading = styled.h2`
    color: ${(props) => props.theme.colors.gray_dark};
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.5625;
`;

const articleLead = css`
    margin: 0;
    ${textEllipsis(5)}
`;

const imgHolder = css`
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    max-height: 175px;

    img {
        object-fit: cover;
        width: 100%;
        height: auto;
        transition: transform 700ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }
`;
