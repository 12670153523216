import React from "react";
import {appLink} from "@web2/gh_routes";

import {ghCommonUrl} from "../../app/utils/read_environment_variables";

interface IProps {
    author: {
        email: string | null;
        name: string;
        slug: string;
    };
    authorImage?: string;
    description: string;
}

export const SchemaAuthorDetail = (props: IProps) => {
    const {author, authorImage, description} = props;
    const url = `${ghCommonUrl}${appLink.article.author.detail({authorSlug: author.slug})}`;
    const json = {
        "@context": "https://schema.org",
        "@type": "Person",
        name: author.name,
        alternateName: author.name,
        url: url,
        image: authorImage,
        email: author.email,
        description: description,
        jobTitle: "autor gethome.pl",
        worksFor: {
            "@type": "Organization",
            name: "portal gethome.pl"
        }
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
