import {sortBy} from "lodash";
import {createSelector} from "reselect";

import {IArticleCategory} from "../../app/interfaces/response/article_category_list";
import {IStore} from "../../app/reducers/hybrid_reducer";
import {parseFirstUnicodeLetterToLatin} from "./parse_unicode_to_latin";

const categoriesSelector = (s: IStore) => s.articles.categoryList.categories || [];

export const mainBlogCategoriesSelector = createSelector(categoriesSelector, (categories: IArticleCategory[]) =>
    sortBy(
        categories.filter((c) => !c.parent),
        [(category) => parseFirstUnicodeLetterToLatin(category.name)]
    )
);

export const cityBlogCategoriesSelector = createSelector(categoriesSelector, (categories: IArticleCategory[]) =>
    sortBy(
        categories.filter((c) => c.parent === 3),
        [(category) => parseFirstUnicodeLetterToLatin(category.name)]
    )
);
